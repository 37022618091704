<template>
	<span>
		<pdf
			:value="output"
			:icon="icon"
			:fileName="fileName"
		>
		</pdf>
	</span>
</template>

<script>
	import Pdf from "@c/downloads/Pdf";

	export default {
		name: "PdfExport",
		props: {
			id: {type: [Array, String]}, // the ID of the response or an array ,
			icon: {type: Boolean, default: false},
			fileName: {type: String, default: ""}
		},
		data: () => {
			return {};
		},
		computed: {
			ids() {
				if (typeof this.id == "string") {
					return [this.id];
				}
				return this.id;
			},
			responses() {
				return this.$store.state.sectionResponses.data;
			},
			sections() {
				return this.$store.state.sections.data;
			},
			organisation() {
				return this.$store.state.organisation.data;
			},
			output() {
				const self = this;
				let output = [
					{
						element: "intro",
						content: `${this.organisation.name}`
					},
					{
						element: "intro",
						content: `Submission generated on ${new Date().toLocaleDateString()}`
					}

				];
				if (!self.responses || !self.sections) {
					return;
				}
				this.ids.forEach((id) => {
					const response = self.responses[id];
					if (!response) {
						return;
					}
					const section = self.sections[response.section];
					if (!section) {
						return;
					}
					output.push({
						element: "title",
						content: section ? section.name : ""
					});
					output.push({
						element: "intro",
						content: section ? section.introduction : ""
					});
					let rows = self.mwsurveyutils.processResponse(response) || [];
					rows = rows.filter((row) =>
						self.mwsurveyutils.isVisible(row.question, response)
					);
					rows.forEach((row) => {
						let questionText = self.$store.getters[
							"questions/getQuestionTexts"
						](row.question);
						output.push({
							element: "question",
							content: questionText.string
						});
						output.push({
							element: "answer",
							content: row.processed_answer
						});
					});
				});
				return output;
			}
		},

		methods: {},
		components: {
			Pdf
		}
	};
</script>
