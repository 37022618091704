<template>
	<span>
		<vue-html2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="true"
			:preview-modal="false"
			:paginate-elements-by-height="1350"
			pdf-format="a4"
			:html-to-pdf-options="{
				pagebreak: {after: '.break', avoid: ['.question', '.answer']},
				enableLinks: true,
				html2canvas: {logging: false},
				filename: fileName
			}"
			ref="html2Pdf"
		>
			<section slot="pdf-content">
				<div style="margin: 20px;">
					<div class="header" style="padding-left: 5px; margin-bottom: 30px">
						<a href="https://m.works"
							><img height="65px" src="../../assets/images/mwlogo.png"
						/></a>
						<a href="https://scottishhealthandsocialcare.dma.works"
							><img
								style="float: right"
								height="65px"
								src="../../assets/images/logo.png"
						/></a>
					</div>
					<div style="font-family: 'Avenir', Helvetica, Arial, sans-serif;">
						<div :style="styles.pageTitle">{{ pageTitle }}</div>
						<div v-for="(item, i) in value" :key="i">
							<div
								:class="item.element"
								:style="styles[item.element]"
								v-html="item.content"
							></div>
						</div>
					</div>
				</div>
			</section>
		</vue-html2pdf>
		<v-tooltip top v-if="icon">
			<template v-slot:activator="{on}">
				<v-btn v-on="on" icon @click="go" outlined>
					<v-icon v-if="icon">mdi-download</v-icon>
				</v-btn>
			</template>
			Download PDF
		</v-tooltip>

		<v-list-item v-else @click="go">
			<v-list-item-title>
				{{ $t("buttons.download") }}
			</v-list-item-title>
		</v-list-item>
	</span>
</template>

<style lang="less"></style>

<script type="text/javascript">
	import VueHtml2pdf from "vue-html2pdf";
	export default {
		name: "Pdf",
		props: {
			value: {type: Array},
			icon: {type: Boolean},
			pagebreak: {type: Object},
			fileName: {type: String, default: "dma"},
			outlined: {type: Boolean}
		},
		data: () => {
			return {
				styles: {
					pageTitle: {
						"line-height": "22px",
						"font-weight": "light",
						"font-size": "22px",
						"margin-bottom": "30px"
					},
					title: {
						"line-height": "18px",
						"font-weight": "light",
						"padding-top": "25px",
						"margin-bottom": "22px",
						"font-size": "22px"
					},
					question: {
						"line-height": "16px",
						"font-weight": "bold",
						"margin-bottom": "4px",
						"margin-top": "15px",
						"font-size": "16px",
						width: "97%"
					},
					intro: {
						"line-height": "22px",
						"font-size": "18px",
						width: "97%"
					},
					answer: {
						"line-height": "13px",
						"font-weight": "normal",
						"margin-bottom": "4px",
						"font-size": "16px"
					}
				}
			};
		},
		computed: {
			pageTitle() {
				return (
					"Scottish Government / COSLA " +
					this.$store.getters["config/assessmentTitle"]
				);
			}
		},
		components: {
			VueHtml2pdf
		},
		methods: {
			go() {
				this.started();
				this.$refs.html2Pdf.generatePdf();
			},
			started() {
				this.$root.$emit("showToast", "Download started");
			}
		}
	};
</script>
