var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1350,"pdf-format":"a4","html-to-pdf-options":{
				pagebreak: {after: '.break', avoid: ['.question', '.answer']},
				enableLinks: true,
				html2canvas: {logging: false},
				filename: _vm.fileName
			}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticStyle:{"margin":"20px"}},[_c('div',{staticClass:"header",staticStyle:{"padding-left":"5px","margin-bottom":"30px"}},[_c('a',{attrs:{"href":"https://m.works"}},[_c('img',{attrs:{"height":"65px","src":require("../../assets/images/mwlogo.png")}})]),_c('a',{attrs:{"href":"https://scottishhealthandsocialcare.dma.works"}},[_c('img',{staticStyle:{"float":"right"},attrs:{"height":"65px","src":require("../../assets/images/logo.png")}})])]),_c('div',{staticStyle:{"font-family":"'Avenir', Helvetica, Arial, sans-serif"}},[_c('div',{style:(_vm.styles.pageTitle)},[_vm._v(_vm._s(_vm.pageTitle))]),_vm._l((_vm.value),function(item,i){return _c('div',{key:i},[_c('div',{class:item.element,style:(_vm.styles[item.element]),domProps:{"innerHTML":_vm._s(item.content)}})])})],2)])])]),(_vm.icon)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","outlined":""},on:{"click":_vm.go}},on),[(_vm.icon)?_c('v-icon',[_vm._v("mdi-download")]):_vm._e()],1)]}}],null,false,2982539096)},[_vm._v(" Download PDF ")]):_c('v-list-item',{on:{"click":_vm.go}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("buttons.download"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }